import StripeCheckout from '../../components/stripe/stripe-checkout';



const BasketStripeCheckout = () => {

     //*ARTICLE A AFFICHER*
    return(
       <>

        <StripeCheckout />
       
        </>
    )
}

export default BasketStripeCheckout