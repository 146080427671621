import React from 'react'
import DefaultLayout from '../components/DefaultLayout'

function BookingCar() {
  return (
    <DefaultLayout>
      <h1>BookingCar</h1>
    </DefaultLayout>
  )
}

export default BookingCar